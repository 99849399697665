@import '../../styles/variables.scss';

.analyse-page {
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-top: 3rem;
    width: 50rem;
    margin: 0 auto;

    @media (max-width: $breaking-point) {
        width: 100%;
        padding: 1rem;
        padding-top: 2rem;
        box-sizing: border-box;
    }

    .group-selection {
        display: flex;
        justify-content: center;
    }

    .filter-container {
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        .segment-picker {
            margin-right: 1rem;
        }
    }
}

.loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
