@import '../../styles/variables.scss';

.impressum-page {
    margin: 2rem auto;
    width: 50rem;

    @media (max-width: $breaking-point) {
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;
    }

    h1 {
        margin-bottom: 5rem;
    }

    h2 {
        margin-top: 4rem;
    }

    a:link,
    a:visited,
    a:hover {
        color: #2e75a1;
        text-decoration: none;
    }
}
