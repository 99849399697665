@import '../../styles/variables.scss';

.date-picker {
    width: 50rem;
    margin: 0 auto;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $breaking-point) {
        width: 100%;
        box-sizing: border-box;
    }

    .date-change-button {
        margin: 0 2rem;

        @media (max-width: $breaking-point) {
            margin: 0;

            &.prev {
                margin-right: 1rem;
            }

            &.next {
                margin-left: 1rem;
            }
        }
    }
}
