@import '../../../styles/variables.scss';

.segment-view {
    margin-bottom: 2rem;
    padding: 1.5rem;
    cursor: pointer;

    h2 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    .date {
        margin-bottom: 1rem;
    }

    .content {
        display: flex;
        align-items: flex-start;

        @media (max-width: $breaking-point) {
            display: block;
        }

        .chart-container {
            width: 25rem;
            height: 10rem;
            position: relative;

            @media (max-width: $breaking-point) {
                width: 100%;
            }

            .date-view-button {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .details-container {
            flex-grow: 1;
            margin-left: 2rem;
            display: flex;
            justify-content: center;

            @media (max-width: $breaking-point) {
                margin: 1rem 0 0 0;
            }

            .details {
                .carbs {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 1rem;
                    font-size: 1.5rem;
                }

                .trend {
                    display: flex;
                    align-items: center;

                    svg {
                        margin: 0 1rem;
                    }
                }

                .diff {
                    display: flex;
                    font-size: 2rem;
                    margin-top: 0.5rem;
                    svg {
                        font-size: 3rem;
                    }
                }

                .bolus-factor-container {
                    display: flex;
                    justify-content: center;
                    margin-top: 0.5rem;
                    .bolus-factor {
                        padding: 0.2rem 0.5rem;
                        border-radius: 5px;
                        background-color: #f0f0f0;
                    }
                }
            }
        }
    }

    .trendSpikeUp {
        transform: rotate(-90deg);
    }

    .trendUp {
        transform: rotate(-45deg);
    }

    .trendDown {
        transform: rotate(45deg);
    }

    .trendSpikeDown {
        transform: rotate(90deg);
    }
}
