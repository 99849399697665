.error-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60rem;
    max-width: 90vw;
    padding: 2rem;
    max-height: 80vh;
    overflow-y: scroll;

    .image-paper {
        padding: 1rem;
        width: auto;
        display: flex;
        align-items: center;
        flex-flow: column;

        img {
            display: block;
            max-width: 100%;
        }

        p {
            text-align: center;
            margin-top: 0.5rem;
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
}
