@import './styles/variables.scss';

h1,
h2,
h3,
h4 {
    font-family: Quicksand, sans-serif !important;
}

.app-content {
    min-height: calc(100vh - #{$footer-height});
    .app-bar {
        font-family: Quicksand, sans-serif !important;
        a:link,
        a:visited,
        a:hover {
            color: white;
            text-decoration: none;
        }
    }

    .menu-button {
        @media (min-width: calc(#{$breaking-point} + 1px)) {
            display: none;
        }
    }
}

.drawer-menu {
    width: 14rem;
    padding: 2rem 1rem;
    .drawer-header {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        padding: 0 1rem;

        img {
            margin-right: 0.5rem;
        }
    }
}

footer {
    font-family: Quicksand, sans-serif !important;
    height: $footer-height;
    background-color: #387ca6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2rem;

    .carbsense-logo {
        display: flex;
        align-items: center;
    }

    a:link,
    a:visited,
    a:hover {
        color: white;
        text-decoration: none;
        margin-left: 3rem;
    }
}
