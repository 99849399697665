@import '../../../styles/variables.scss';

.current-date-view {
    margin: 2rem auto;
    width: 50rem;

    @media (max-width: $breaking-point) {
        width: 100%;
        margin: 0;
    }

    ul {
        list-style-type: none;
        margin: 3rem 0 0 0;
        padding: 0;

        @media (max-width: $breaking-point) {
            margin-top: 2rem;
            padding: 0;
        }
    }
}
