@import '../../styles/variables.scss';

.day-view-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60rem;
    max-width: 90vw;
    padding: 2rem;
    padding-top: 5rem;
    max-height: 80vh;
    height: 40vh;

    background-color: white;

    h2 {
        position: absolute;
        top: 1rem;
        left: 3rem;
    }
}
