@import '../../styles/variables.scss';

.top-menu {
    flex-grow: 1;
    nav {
        ul {
            display: flex;
            list-style-type: none;

            @media (max-width: $breaking-point) {
                display: none;
            }

            li {
                margin-left: 2rem;
            }
        }
    }
}

.drawer-menu {
    nav {
        ul {
            list-style-type: none;
            margin: 0 1rem;
            padding: 0;

            li {
                padding: 1rem 0rem;

                a:link,
                a:visited,
                a:hover {
                    color: #555555;
                    text-decoration: none;
                    font-weight: 500;
                }
            }
        }
    }
}
