@import '../../styles/variables.scss';
.start-page {
    display: flex;
    justify-content: center;

    h1 {
        text-align: center;
        margin-bottom: 5rem;
    }
    .input-container {
        width: 50rem;

        @media (max-width: $breaking-point) {
            width: 100%;
            padding: 2rem;
            box-sizing: border-box;
        }

        .segment-select {
            width: 12rem;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    }
}
